import React, { Component } from "react"
import FormMaker from "../../../components/formMaker"
import { connect } from "react-redux"
import { storeCurrentRequestId } from "../../../redux/actions/"
import { Steps } from "intro.js-react"
import IntroWrapper from '../../../components/client-only-wrapper';

export class NewSocialMediaContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: {
        showProgress: true,
        dontShowAgain: true,
        showBullets: false,
        scrollToElement: false,
      },
      showUsers: true,
      initialStep: 0,
      steps: [
        {
          element: "#brief",
          title: "Reason",
          intro:
            "Why are you running this social media campaign? What is your goal?",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: "#selectfiles",
          title: "Inspiration",
          intro:
            "Please upload any examples or inspiration we can use to create this social media campaign",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".submitLater",
          title: "NOTE:",
          intro:
            "You can save your progress here. Just remember that for it to be sent to head office you have to select the 'submit form' button",
          position: "top",
          tooltipClass: "intro-title",
        },
      ],
    }
  }

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }))
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }))
  }
  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
      options,
      scrollToElement,
    } = this.state
    console.log(this.props)
    return (
      <div>
        <IntroWrapper>
          <Steps
            scrollToElement={scrollToElement}
            options={options}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
        </IntroWrapper>

        <button
          className="fixed bottom-20 right-20 h-28 w-28 bg-brand-blue rounded-full text-white font-bold flex items-center justify-center shadow-lg z-50 font-medium"
          onClick={this.toggleSteps}
        >
          <span className="font-medium">Need Help?</span>
        </button>
        <FormMaker
          formId="602db4a3ba5f15001bb53021"
          formType="social-medias"
          submitLater="submitLater"
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  current_request_id: state.current_request_id,
})

function mapDispatchToProps(dispatch) {
  return {
    storeCurrentRequestId: id => dispatch(storeCurrentRequestId(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewSocialMediaContent)
